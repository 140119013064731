const _sm = "0 1px 2px 0 rgb(0 0 0 / 0.05)"
const _DEFAULT = "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)"
const _md = "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
const _lg = "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
const _xl = "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)"
const _2xl = "0 25px 50px -12px rgb(0 0 0 / 0.25)"
const _inner = "inset 0 2px 4px 0 rgb(0 0 0 / 0.05)"
const _none = "none"
const _header = "0 1px 0 rgba(0,0,0,.25)"
const config = { "sm": _sm, "DEFAULT": _DEFAULT, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "inner": _inner, "none": _none, "header": _header,  }
export { config as default, _sm, _DEFAULT, _md, _lg, _xl, _2xl, _inner, _none, _header }