const _xs = ["0.75rem",{"lineHeight":"1.3333333333333333/* 16px */"}]
const _sm = ["0.875rem",{"lineHeight":"1.4285714285714286/* 20px */"}]
const _base = ["1rem",{"lineHeight":"1.5/* 24px */"}]
const _lg = ["1.25rem",{"lineHeight":"1.4/* 28px */"}]
const _xl = ["1.25rem",{"lineHeight":"1.4/* 28px */","fontWeight":300}]
const _2xl = ["1.5rem",{"lineHeight":"1.3333333333333333/* 32px */","fontWeight":300}]
const _3xl = ["1.875rem",{"lineHeight":"1.2/* 36px */","fontWeight":300}]
const _4xl = ["2.25rem",{"lineHeight":"1.3333333333333333/* 48px */","fontWeight":300}]
const _5xl = ["3rem",{"lineHeight":"1.3333333333333333/* 64px */","fontWeight":300}]
const _6xl = ["4rem",{"lineHeight":"1.25/* 80px */","fontWeight":300}]
const config = { "xs": _xs, "sm": _sm, "base": _base, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "6xl": _6xl, "sm-spacing": ["0.875rem",{"lineHeight":"1.4285714285714286/* 20px */","fontWeight":400,"letterSpacing":"1px"}], "sm-semibold": ["0.875rem",{"lineHeight":"1.4285714285714286/* 20px */","fontWeight":600}], "base-semibold": ["1rem",{"lineHeight":"1.5/* 24px */","fontWeight":600}], "lg-bold": ["1.25rem",{"lineHeight":"1.4/* 28px */","fontWeight":700}], "lg-semibold": ["1.25rem",{"lineHeight":"1.4/* 28px */","fontWeight":600}], "xl-bold": ["1.25rem",{"lineHeight":"1.4/* 28px */","fontWeight":700}], "xl-semibold": ["1.25rem",{"lineHeight":"1.4/* 28px */","fontWeight":600}], "2xl-bold": ["1.5rem",{"lineHeight":"1.3333333333333333/* 32px */","fontWeight":700}], "2xl-semibold": ["1.5rem",{"lineHeight":"1.3333333333333333/* 32px */","fontWeight":600}], "2xl-italic": ["1.5rem",{"lineHeight":"1.3333333333333333/* 32px */","fontWeight":400,"fontStyle":"italic"}], "3xl-bold": ["1.875rem",{"lineHeight":"1.2/* 36px */","fontWeight":700}], "3xl-semibold": ["1.875rem",{"lineHeight":"1.2/* 36px */","fontWeight":600}], "4xl-bold": ["2.25rem",{"lineHeight":"1.3333333333333333/* 48px */","fontWeight":700}], "5xl-bold": ["3rem",{"lineHeight":"1.3333333333333333/* 64px */","fontWeight":700}], "6xl-bold": ["4rem",{"lineHeight":"1.25/* 80px */","fontWeight":700}],  }
export { config as default, _xs, _sm, _base, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _6xl }