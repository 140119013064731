const _transparent = "transparent"
const _current = "currentColor"
const _black = "#080808"
const _white = "#ffffff"
const _white2 = "#F1F2E9"
const _gray1 = "#C5C7BC"
const _gray2 = "#9E9F96"
const _gray3 = "#272A2A"
const _red = {"20":"#E38F78","40":"#DC4F35","50":"#CF4145","60":"#A52A1B","80":"#D70036","DEFAULT":"#D70036"}
const _blue = {"60":"#4F84B5","80":"#3E6A93"}
const _green = {"60":"#345D45","80":"#22402E","DEFAULT":"#497F74"}
const _violet = {"20":"#E8C6EC"}
const _purple = "#A015FF"
const _purple2 = "#7810BF"
const _purple3 = "#B26CAA"
const _purple4 = "#A0426B"
const _yellow = {"20":"#EBE6C0","40":"#F4D72B","60":"#ECBA32","80":"#E79F1D","DEFAULT":"#ECBA32"}
const _disabled = "#F1F2E9"
const _primary = "#D70036"
const _auto = "auto"
const config = { "transparent": _transparent, "current": _current, "black": _black, "white": _white, "white2": _white2, "gray1": _gray1, "gray2": _gray2, "gray3": _gray3, "red": _red, "blue": _blue, "green": _green, "violet": _violet, "purple": _purple, "purple2": _purple2, "purple3": _purple3, "purple4": _purple4, "yellow": _yellow, "disabled": _disabled, "primary": _primary, "auto": _auto,  }
export { config as default, _transparent, _current, _black, _white, _white2, _gray1, _gray2, _gray3, _red, _blue, _green, _violet, _purple, _purple2, _purple3, _purple4, _yellow, _disabled, _primary, _auto }